/**
 * Path definition to route between JavaScript pages.
 * Paths can be accessed through Path's static
 * members, e.g., Path.index etc.
 * @type {string}
 */
const INDEX = "/";
const NOTFOUND = "/notfound";

const LOGIN = "/login";
const REGISTRATION = "/registration";

const DASHBOARD = "dashboard";
const CLIENT = "client/view";
const IPLIST = "client/ip-lists";
const PAYMENT = "client/payment";
const APIDOC = "client/api-doc";

const DEEPING_HISTORY = "dipping/history";
const DEEPING_MNP_HISTORY = "dipping/mnp-history";
const DEEPING_SEARCH_PORT_COUNT = "dipping/search-port";

const INVOICES = "invoices";

const TARIFF = "Tariff";

const PROFILE = "profile";
const BUSSINESS_USER = "bussiness-user";
const SYSTEM_USER = "system-user";
const ROLE_MANAGEMENT = "role-management";
const USERVERIFY = "/verify";

export class Path {
  static get userVerify() {
    return USERVERIFY;
  }
  static get invoices() {
    return INVOICES;
  }
  static get roleManagement() {
    return ROLE_MANAGEMENT;
  }
  static get systemUser() {
    return SYSTEM_USER;
  }
  static get bussinessUser() {
    return BUSSINESS_USER;
  }
  static get tariff() {
    return TARIFF;
  }
  static get deepingHistory() {
    return DEEPING_HISTORY;
  }

  static get deepingMNPHistory() {
    return DEEPING_MNP_HISTORY;
  }
  static get deepingSearchPortCount() {
    return DEEPING_SEARCH_PORT_COUNT;
  }

  static get index() {
    return INDEX;
  }

  static get login() {
    return LOGIN;
  }

  static get registration() {
    return REGISTRATION;
  }
  static get payment() {
    return PAYMENT;
  }
  static get apiDoc() {
    return APIDOC;
  }
  static get dashboard() {
    return DASHBOARD;
  }

  static get client() {
    return CLIENT;
  }

  static get ipList() {
    return IPLIST;
  }
  static get profile() {
    return PROFILE;
  }

  static get notFound() {
    return NOTFOUND;
  }
}

const API_USER = "ttu/api/v1/users";
const API_FILE_MANaGMENT = "dm/api/v1/local/files";
const API_CLIENT_PAYMENT = "mnp-setup/api/v1/mnp/clientpayment";
const API_TARIFF = "mnp-setup/api/v1/mnp/tariff";
const API_TARIFF_DISTINCT = "mnp-setup/api/v1/mnp/tariff/distinct";
const API_IP_ADDRESS = "mnp-setup/api/v1/mnp/ipaddress";
const API_BUSINESS_USER = "mnp-setup/api/v1/mnp/userlist/business";
const API_SYSTEM_USER = "mnp-setup/api/v1/mnp/userlist/system";
const API_USER_CREATION_ADM = "/mnp-setup/api/v1/mnp/registration";
const API_USER_STATUS = "/ttu/api/v1/users/status";
const API_REQUEST_COUNT = "mnp-setup/api/v1/mnp/requestcount";
const API_RESET_PASSWORD = "/ttu/api/v1/users/password/reset";
const API_OWN_INFO = "mnp-setup/api/v1/mnp/userdetails";
const API_MNP_HISTORY = "mnp-reporting/api/v1/mnp-history";
const API_FEATURE_LIST = "/ttu/api/v1/features";
const API_ROLE = "/ttu/api/v1/roles";
const API_USER_ROLE_FEATURE = "/ttu/api/v1/users/roles";
const API_BILL = "mnp-setup/api/v1/mnp/monthlybill";
const API_TPS = "mnp-setup/api/v1/mnp/tps";
const API_SEARCH_PORT = "mnp-setup/api/v1/mnp/portcount";

const API_DEEPING_HISTORY = "";

// api path

export class API {
  static get deepingSearchPort() {
    return API_SEARCH_PORT;
  }
  static get tps() {
    return API_TPS;
  }
  static get bill() {
    return API_BILL;
  }

  static get userRoleFeatureList() {
    return API_USER_ROLE_FEATURE;
  }
  static get roleList() {
    return API_ROLE;
  }
  static get featureList() {
    return API_FEATURE_LIST;
  }
  static get mnpHistory() {
    return API_MNP_HISTORY;
  }
  static get ownInfo() {
    return API_OWN_INFO;
  }
  static get resetPassword() {
    return API_RESET_PASSWORD;
  }
  static get requestCount() {
    return API_REQUEST_COUNT;
  }
  static get fileManagement() {
    return API_FILE_MANaGMENT;
  }

  static get userStatusChange() {
    return API_USER_STATUS;
  }
  static get user() {
    return API_USER;
  }

  static get userCreationAdm() {
    return API_USER_CREATION_ADM;
  }

  static get tariff() {
    return API_TARIFF;
  }
  static get tariffDistinct() {
    return API_TARIFF_DISTINCT;
  }

  static get role() {
    return API_ROLE;
  }
  static get systemUser() {
    return API_SYSTEM_USER;
  }
  static get businessUser() {
    return API_BUSINESS_USER;
  }
  static get clientPaymentList() {
    return API_CLIENT_PAYMENT;
  }

  static get ipAddress() {
    return API_IP_ADDRESS;
  }

  static get deepingHistory() {
    return API_DEEPING_HISTORY;
  }
}
