import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { Suspense } from "react";
import {
  Routes,
  Route,
  Redirect,
  useNavigate,
  Navigate,
} from "react-router-dom";

import { withRouter } from "../../utils/with-router";
import { Auth } from "../../api/auth";


import RequireAuth from "../../api/required-auth"
import { configureAxios } from "../../api/config";
import IdelTimerContainer from "../../utils/idel-timer";

import { API, Path } from "./config";



const NotFound = React.lazy(() => import( "./pages/not-found/not-found"));
const Unauthorized = React.lazy(() => import( "./pages/not-found/unauthorized"));
const Home = React.lazy(() => import( "./pages/home/home"));
const Login = React.lazy(() => import( "./pages/login/login"));
const Dashboard = React.lazy(() => import( "./pages/dashboard/dashboard"));
const Client = React.lazy(() => import( "./pages/client/client-add/client"));
const IPList = React.lazy(() => import( "././pages/client/ip-lists/ip-list"));
const Payment = React.lazy(() => import( "./pages/client/payment/payment"));
const Invoices = React.lazy(() => import( "./pages/invoices/invoices"));
const Tariff = React.lazy(() => import( "./pages/tariff/tariff"));
const APIDocPage = React.lazy(() => import( "./pages/client/api-doc/api-doc"));
const Profile = React.lazy(() => import(  "./pages/setting/profile"));
const DeepingHistory = React.lazy(() => import(  "./pages/deeping/deeping-history/deeping-history"));
const DeepingMNPHistory = React.lazy(() => import(  "./pages/deeping/deeping-mnp-history/deeping-mnp-history"));
const DeepingSearchPortCount = React.lazy(() => import(  "./pages/deeping/deeping-search-port-count/deeping-search-port-count"));
const RoleManagement = React.lazy(() => import(  "./pages/users/role/role-management"));
const SystemUser = React.lazy(() => import( "./pages/users/system/system-user"));
const UserVerify = React.lazy(() => import(  "./pages/login/user-verify"));




class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    this.props.history(Path.dashboard);

    return <Navigate to={Path.dashboard} />;
  }
}

class MNPAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      user: "",
      featureList:''
    };

    this.sessionClear = () => {
      localStorage.setItem(process.env.REACT_APP_USER_SESSION, "signout");
      
      configureAxios({ authToken: null, authCallback: this.authCallback });

      this.userHasAuthenticated(false);
      this.props.history.push(Path.login);
    };

    this.logout = () => {
      localStorage.setItem(process.env.REACT_APP_USER_SESSION, "signout");

      Auth.signout();
      
      configureAxios({ authToken: null, authCallback: this.authCallback });

      this.userHasAuthenticated(false);
      this.props.history(Path.login);
    };

    this.authCallback = (response) => {
      localStorage.removeItem(process.env.REACT_APP_SESSION);
      localStorage.setItem(process.env.REACT_APP_USER_SESSION, "signout");
    };

    Auth.setAuthCallback(this.authCallback);

    let result = Auth.currentSession();
    configureAxios({
      authToken: result == null ? null : result.accessToken,
      authCallback: this.authCallback,
    });
  }

  componentWillMount() {

    try {
      let processFeatureList=[]

      let result = Auth.currentSession();
      if (result) {
        this.userHasAuthenticated(true, result);
      //   let tokenRoles=result?.data?.roleList[0]
      //  if(tokenRoles.includes('Admin')){
      //   processFeatureList.push('Admin')
      //   this.setState({
      //     featureList:processFeatureList
      //   })
      //  }
      //  else{
      //   getApiCallDynamic({roleName:tokenRoles},API.userRoleFeatureList).then(res=>{
      //     if(res?.status===200){
      //       let processData=res.data.featureList
      //       processData.map(item=>{
      //         if(item.actionChosen.length>0){
      //           processFeatureList.push(item.featureName)
      //         }
      //       })
      //       this.setState({
      //         featureList:processFeatureList
      //       })

      //     }
      //   })
      //  }

     
      }
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }
  }

  userHasAuthenticated = (authenticated, data = "") => {
    this.setState({ isAuthenticated: authenticated, user: data });
  };

  handleLogout = (event) => {
    
    this.logout();
  };

  render() {
 //   console.log("user", JSON.stringify(this.state.user));
  

    //production cancel out methods
    if (process.env.NODE_ENV === "production") {
      const noop = () => {};
      [
        "assert",
        "clear",
        "count",
        "debug",
        "dir",
        "dirxml",
        "error",
        "exception",
        "group",
        "groupCollapsed",
        "groupEnd",
        "info",
        "log",
        "markTimeline",
        "profile",
        "profileEnd",
        "table",
        "time",
        "timeEnd",
        "timeline",
        "timelineEnd",
        "timeStamp",
        "trace",
        "warn",
      ].forEach((method) => {
        window.console[method] = noop;
      });
    }

    const childProps = {
      isAuthenticated: this.state.isAuthenticated,

      user: this.state.user,
     
      userHasAuthenticated: this.userHasAuthenticated,
      handleLogout: this.handleLogout,
      sessionClear: this.sessionClear,
      featureList: this.state.user.featureList,
    };

    if (this.state.isAuthenticated) {
      if (this.props.location.pathname === Path.login) {
        // alert("MNPAdmin1 - " + childProps.isAuthenticated + " " + this.props.location.pathname);
        return <Navigate to={Path.index} />;
      }
    } else {
      const guestPaths = [Path.login, Path.registration,Path.userVerify];
      if (!guestPaths.includes(this.props.location.pathname)) {
        return <Navigate to={Path.login} />;
      }
    }

    return (
      <Suspense fallback={<></>}>
        <div className="MNPAdmin ">
          <IdelTimerContainer props={childProps} />
          <Routes>

          <Route path="unauthorized" element={<Unauthorized />} />

            <Route
              path={Path.index}
              element={<Index {...this.props} />}
              // render={(routeProps) => (
              //     <Index routeProps={routeProps} />
              // )}
            />

            {/* Admin */}

            <Route
              path={Path.index + "*"}
              element={<Home {...this.props} {...childProps} />}
              // render={(routeProps) => (
              //     <Index routeProps={routeProps} />
              // )}
            >
              
              
              <Route
                            element={
                                <RequireAuth
                                    allowedRoles={['Dashboard','Admin']}
                                    {...childProps}
                                    {...this.props}
                                />
                            }
                        >
              <Route path={Path.dashboard} element={<Dashboard  />} />
              </Route>
              <Route
                            element={
                                <RequireAuth
                                    allowedRoles={['View/Edit Client','Admin']}
                                    {...childProps}
                                    {...this.props}
                                />
                            }
                        >
              <Route path={Path.client} element={<Client />} />
              </Route>
              <Route
                            element={
                                <RequireAuth
                                    allowedRoles={['IP Lists','Admin']}
                                    {...childProps}
                                    {...this.props}
                                />
                            }
                        >
              <Route path={Path.ipList} element={<IPList />} />
              </Route>

              <Route
                            element={
                                <RequireAuth
                                    allowedRoles={['Add/Receive Payment','Admin']}
                                    {...childProps}
                                    {...this.props}
                                />
                            }
                        >

              <Route path={Path.payment} element={<Payment />} />
              </Route>
              <Route
                            element={
                                <RequireAuth
                                    allowedRoles={['API Doc','Admin']}
                                    {...childProps}
                                    {...this.props}
                                />
                            }
                        >

              <Route path={Path.apiDoc} element={<APIDocPage />} /> </Route>
              <Route
                            element={
                                <RequireAuth
                                    allowedRoles={['Dipping History','Admin']}
                                    {...childProps}
                                    {...this.props}
                                />
                            }
                        >

              <Route path={Path.deepingHistory} element={<DeepingHistory />} /> </Route>
              <Route
                            element={
                                <RequireAuth
                                    allowedRoles={['MNP History','Admin']}
                                    {...childProps}
                                    {...this.props}
                                />
                            }
                        >

              <Route path={Path.deepingMNPHistory} element={<DeepingMNPHistory />} /> </Route>
              <Route
                            element={
                                <RequireAuth
                                    allowedRoles={['Search Port Count','Admin']}
                                    {...childProps}
                                    {...this.props}
                                />
                            }
                        >

              <Route
                path={Path.deepingSearchPortCount}
                element={<DeepingSearchPortCount />}
              /> </Route>
        
        <Route
                            element={
                                <RequireAuth
                                    allowedRoles={['Invoices','Admin']}
                                    {...childProps}
                                    {...this.props}
                                />
                            }
                        >

              <Route path={Path.invoices} element={<Invoices />} /> </Route>
              <Route
                            element={
                                <RequireAuth
                                    allowedRoles={['Tariff','Admin']}
                                    {...childProps}
                                    {...this.props}
                                />
                            }
                        >

              <Route path={Path.tariff} element={<Tariff />} /> </Route>
              <Route
                            element={
                                <RequireAuth
                                    allowedRoles={['Role Managment','Admin']}
                                    {...childProps}
                                    {...this.props}
                                />
                            }
                        >

              <Route path={Path.roleManagement} element={<RoleManagement />} /> </Route>
              <Route
                            element={
                                <RequireAuth
                                    allowedRoles={['System User','Admin']}
                                    {...childProps}
                                    {...this.props}
                                />
                            }
                        >

              <Route path={Path.systemUser} element={<SystemUser />} /> </Route>
              {/* <Route
                            element={
                                <RequireAuth
                                    allowedRoles={['Bussiness User','Admin']}
                                    {...childProps}
                                    {...this.props}
                                />
                            }
                        >

              <Route path={Path.bussinessUser} element={<BusinessUser />} /> </Route> */}



              <Route path={Path.profile} element={<Profile {...this.props} {...childProps} />} />


              <Route path="*" element={<NotFound />} />
            </Route>


            <Route
              path={Path.login}
              element={<Login {...this.props} {...childProps} />}
            />

       
            <Route
                  path={Path.userVerify}
                  element={
                      <UserVerify {...this.props} {...childProps} />
                  }
              />
            {/* Finally, catch all unmatched routes */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Suspense>
    );
  }
}

export default withRouter(MNPAdmin);
