import React from "react";
import MNPAdminAPP from "./MNPAdminApp";

export default {
  routeProps: {
    path: "/*",
    element: <MNPAdminAPP />,
  },
  name: "MNP Admin",
};
